import React from 'react';
import 'swiper/swiper-bundle.css';
// import { Helmet } from 'react-helmet';
import classes from '../../css/styles.module.css';
import planAvistamiento from '../../img/plan-avistamiento.png';
import planebannerD from '../../img/planes-banner-D.png';
import dividerh from '../../img/divider-h.svg';
import step1 from '../../img/step-1.svg';
import verticald from '../../img/vertical-divider.svg';
import step2 from '../../img/step-2.svg';
import step3 from '../../img/step-3.svg';
import planRuta from '../../img/plan-ruta-a-riendas.png';
import planSafari from '../../img/plan-safari-nocturno.png';
import planCasaDuVelo from '../../img/plan-casa-du-velo.png';
import planCafetera from '../../img/Experiencia Cafetera .png';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import planCordillera from '../../img/plan-cordillera-p.jpg';
import  SEO  from '../functions/MetaTags';

function EcoRolling() {

  const lang = i18next.language.split('-')[0] || 'en';

  const routes = {
    en: {
      birdWatching: '/experiences/bird-watching',
      horsebackTrails: '/experiences/horseback-trails',
      jungleLights: '/experiences/jungle-lights',
      dayCasaDuvelo: '/experiences/day-casa-duvelo',
      coffeeExperience: '/experiences/coffee-experience',
      TourMountainRange: '/experiences/tour-mountain-range',
    },
    es: {
      birdWatching: '/experiences/bird-watching',
      horsebackTrails: '/experiences/horseback-trails',
      jungleLights: '/experiences/jungle-lights',
      dayCasaDuvelo: '/experiences/day-casa-duvelo',
      coffeeExperience: '/experiences/coffee-experience',
      TourMountainRange: '/experiences/tour-mountain-range',
    },
  };

  const currentRoutes = routes[lang] || routes.en;

  const content = {
    en: {
      seoname: "EcoRolling",
      seotitle: "Ecorolling | Our Experiences",
      seodescription: "Live the experience of being able to enjoy nature and the different plans we have for you" ,
      image: "https://eco-rolling.com/static/media/Home-hero-area-D.d98ae75486539318c38f.png",
      title: "Our Experiences",
      subtitle: "Embark on a unique journey with Eco Rolling, where every moment is an opportunity to explore the wonder of Colombian biodiversity.",
      plan1_title: "Bird Watching",
      plan1_desc: "A fascinating world of colorful plumage, with more than 245 species of birds recorded, our bird watching in this region becomes a unique and captivating experience.",
      plan2_title: "Horseback Trails",
      plan2_desc: "Immerse yourself in the beauty of rolling landscapes, coffee fields, and secrets that only reveal themselves when you explore the region on horseback.",
      plan3_title: "Jungle Lights",
      plan3_desc: "With the help of expert guides equipped with specialized flashlights, you'll explore the jungle in the dark, revealing nocturnal creatures that usually hide during the day.",
      plan4_title: "Day at Casa DuVelo",
      plan4_desc: "Whether you are an experienced cyclist or just getting started, we have options for all skill levels and experience.",
      plan5_title: "Coffee Experience",
      plan5_desc: "With our tour plan, you'll immerse yourself in the authentic beauty and culture of the Coffee Axis, living unique experiences every step of the way.",
      plan6_title: "Mountain Range Tour",
      plan6_desc: "Discover the Quindian Mountains in a Jeep Willys, an emblem of the Coffee Region, as you explore picturesque towns and enjoy breathtaking natural landscapes between mountains and coffee plantations.",
      view_more: "View More",
      customize_title: "Customize Your Unique Experience in the Coffee Axis!",
      customize_desc: "At Eco Rolling, we believe that every journey is unique, just like every traveler. That's why we offer you the opportunity to design your own adventure with our customizable packages in Colombia's Coffee Axis.",
      step1_title: "Choose Your Pickup and Drop-off Point",
      step1_desc: "Start your journey by choosing your pickup time and location. Whether you're in a nearby city or at the airport, we customize your itinerary for your convenience. When the adventure ends, we'll drop you off at your chosen location.",
      step2_title: "Select Your Style of Accommodation",
      step2_desc: "We offer three accommodation options, each designed to suit different tastes and budgets. From cozy local inns to luxury boutique hotels, choose the place that reflects your style. Don't worry about the details—we'll take care of everything.",
      step3_title: "Choose Your Style of Travel",
      step3_desc: "At Eco Rolling, how you travel is as important as the destination itself. We offer you the option to design your experience, choosing activities that resonate with your interests, from hiking and bird watching to cultural immersion in local communities.",
      eco_desc: "At Eco Rolling, we give you the power to create the perfect adventure in the Coffee Axis. Customize your package today and discover the beauty of Colombia your way!"
    },
    es: {
      seoname: "EcoRolling",
      seotitle: "Ecorolling | Nuestras Experiencias",
      seodescription: "Vive la experiencia de poder disfrutar con la naturaleza y los diferentes planes que tenemos para ti" ,
      image: "https://eco-rolling.com/static/media/Home-hero-area-D.d98ae75486539318c38f.png",
      title: "Nuestras Experiencias",
      subtitle: "Embárcate en una travesía única con Eco Rolling, donde cada momento es una oportunidad para explorar la maravilla de la biodiversidad colombiana.",
      plan1_title: "Avistamiento de Aves",
      plan1_desc: "Un mundo fascinante de plumajes coloridos, con más de 245 especies de aves registradas, nuestro avistamiento de aves en esta región se convierte en una experiencia única y cautivadora.",
      plan2_title: "Rutas a Riendas",
      plan2_desc: "Sumérgete en la belleza de los paisajes ondulantes, los campos de café y los secretos que solo se revelan cuando exploras la región a lomo de un caballo.",
      plan3_title: "Luces de la Selva",
      plan3_desc: "Con la ayuda de guías expertos equipados con linternas especializadas, explorarás la selva en la oscuridad, revelando criaturas nocturnas que normalmente se esconden durante el día.",
      plan4_title: "Día en Casa DuVelo",
      plan4_desc: "Ya sea que seas un ciclista experimentado o estés dando tus primeros pedaleos, tenemos opciones para todos los niveles de habilidad y experiencia.",
      plan5_title: "Experiencia Cafetera",
      plan5_desc: "Con nuestro plan turístico, te sumergirás en la auténtica belleza y cultura del Eje Cafetero, viviendo experiencias únicas en cada paso del camino.",
      plan6_title: "Tour en la Cordillera",
      plan6_desc: "Descubre la Cordillera Quindiana en un Jeep Willys, emblema del Eje Cafetero, mientras recorres pintorescos pueblos y disfrutas de impresionantes paisajes naturales entre montañas y cafetales.",
      view_more: "Ver más",
      customize_title: "¡Personaliza Tu Experiencia Única en el Eje Cafetero!",
      customize_desc: "En Eco Rolling, creemos que cada viaje es único, al igual que cada viajero. Por eso, te ofrecemos la oportunidad de diseñar tu propia aventura con nuestros paquetes personalizables en el Eje Cafetero de Colombia.",
      step1_title: "Elige tu Punto de Recogida y Devolución",
      step1_desc: "Comienza tu viaje eligiendo el lugar y la hora de tu recogida. Ya sea que estés en una ciudad cercana o en el aeropuerto, personalizamos tu itinerario para que se ajuste a tu comodidad. Cuando la aventura llegue a su fin, te devolvemos al lugar que elijas.",
      step2_title: "Selecciona tu Estilo de Alojamiento",
      step2_desc: "Ofrecemos tres opciones de alojamiento, cada una diseñada para adaptarse a diferentes gustos y presupuestos. Desde acogedoras posadas locales hasta lujosos hoteles boutique, elige el lugar que refleje tu estilo. ¡Y no te preocupes por los detalles! Nos encargamos de todo.",
      step3_title: "Elige tu Estilo de Viaje",
      step3_desc: "Con Eco Rolling, la forma en que viajas es tan importante como el destino en sí. Te ofrecemos la opción de diseñar tu experiencia, eligiendo actividades que resuenen con tus intereses, desde el senderismo y la observación de aves hasta la inmersión cultural en las comunidades locales.",
      eco_desc: "En Eco Rolling, te damos el poder de crear la aventura perfecta en el Eje Cafetero. ¡Personaliza tu paquete hoy y descubre la belleza de Colombia a tu manera!"
    },
  };

  const currentContent = content[lang] || content.en;

  return (
    <div>
         <SEO
        title={currentContent.seotitle}
        description={currentContent.seodescription}
        image={currentContent.image}
        name={currentContent.seoname}
    />
    
      <main className={classes["main-content"]}>
        <section className={classes["flex-col"]}>
          <h1 className={classes["project-title"]}>{content[lang].title}</h1>
          <p className={classes["project-subtitle"]}>
            {content[lang].subtitle}
          </p>
          <div className={`${classes["plans-container"]} ${classes.flex} ${classes['card-container']} ${classes["mt-1"]} ${classes["mb-2"]}`}>
            <div className={classes['card-planD']}>
              <img src={planAvistamiento} alt={("img of plan")} className={classes.img} />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan1_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan1_desc}
                </p>
                <Link to={`/${lang}${currentRoutes.birdWatching}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>

            <div className={classes["card-planD"]}>
              <img src={planRuta} alt={("img of plan")} className={classes.img} />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan2_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan2_desc}
                </p>
                <Link to={`/${lang}${currentRoutes.horsebackTrails}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>

            <div className={classes["card-planD"]}>
              <img src={planSafari} alt={("img of plan")} className={classes.img} />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan3_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan3_desc}
        
                </p>
                <Link to={`/${lang}${currentRoutes.jungleLights}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>

            <div className={classes["card-planD"]}>
              <img
                src={planCasaDuVelo}
                alt={"img of plan"}
                className={classes.img}
              />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan4_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan4_desc}
         
                </p>
                <Link to={`/${lang}${currentRoutes.dayCasaDuvelo}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>

            <div className={classes["card-planD"]}>
              <img src={planCafetera} alt={("img of plan")} className={classes.img} />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan5_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan5_desc}
                </p>
                <Link to={`/${lang}${currentRoutes.coffeeExperience}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>

            <div className={classes["card-planD"]}>
              <img src={planCordillera} alt={("img of plan")} className={classes.img} />
              <div className={classes["card-body"]}>
                <h2 className={`${classes["card-title"]} ${classes["plan-title"]}`}>{content[lang].plan6_title}</h2>
                <p className={classes["card-description"]}>
                  {content[lang].plan6_desc}
                </p>
                <Link to={`/${lang}${currentRoutes.TourMountainRange}`}>
                  <button className={classes['main-btn']}>
                    {i18next.t('View More')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className={`${classes["flex-row"]} ${classes["section-banner"]}`}>
          <div className={classes["img-left"]}>
            <img src={planebannerD} alt={("img of plan")} className={classes.img} />
          </div>
          <div className={`${classes["desc-right"]} ${classes["flex-col"]}`}>
            <h2>{content[lang].customize_title}</h2>
            <img src={dividerh} alt={("divider")} className={classes.img} />
            <p>
              {content[lang].customize_desc}</p>
            <img
              src={dividerh}
              alt={("divider")}
              className={`${classes.img} ${classes["div-inverse"]}`}
            />
          </div>
        </section>

        <section className={`${classes["plans-steps"]} ${classes["flex-col"]}`}>
          <div
            className={`${classes.flex} ${classes["card-container"]} ${classes["mt-1"]} ${classes["mb-2"]}`}
          >
            <div
              className={`${classes["nosotros-card"]} ${classes["flex-col"]}`}
            >
              <img
                src={step1}
                alt={("step 1")}
                className={`${classes["mb-1"]} ${classes["img-step"]}`}
              />
              <div>
                <h2 className={classes["card-title"]}>
                  {content[lang].step1_title}
                </h2>
                <p className={`${classes["card-description"]} ${classes["card-steps1"]}`}>
                  {content[lang].step1_desc}</p>
              </div>
            </div>
            <div className={classes["vertical-divider"]}>
              <img
                src={verticald}
                alt={("vertical-divider")}
                className={classes.img}
              />
            </div>
            <div
              className={`${classes["nosotros-card"]} ${classes["flex-col"]}`}
            >
              <img
                src={step2}
                alt={("step 2")}
                className={`${classes.img} ${classes["mb-1"]} ${classes["img-step"]}`}
              />
              <div>
                <h2 className={classes["card-title"]}>
                  {content[lang].step2_title}
                </h2>
                <p className={`${classes["card-description"]} ${classes["card-steps2"]}`}>
                  {content[lang].step2_desc}</p>
              </div>
            </div>
            <div className={classes["vertical-divider"]}>
              <img
                src={verticald}
                alt={("vertical-divider")}
                className={classes.img}
              />
            </div>
            <div
              className={`${classes["nosotros-card"]} ${classes["flex-col"]}`}
            >
              <img
                src={step3}
                alt={("step 3")}
                className={`${classes["mb-1"]} ${classes["img-step"]}`}
              />
              <div>
                <h2 className={classes["card-title"]}>{content[lang].step3_title}</h2>
                <p className={`${classes["card-description"]} ${classes["card-steps3"]}`}>
                  {content[lang].step3_desc}
                </p>
              </div>
            </div>
          </div>
          <div className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}>
            <h3 className="">
              {content[lang].eco_desc}
            </h3>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EcoRolling;
