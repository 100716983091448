import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import aves from '../../img/card-img-aves.png';
import ruta from '../../img/card-img-ruta.png';
import luces from '../../img/card-img-luces.png';
import Experiencia from '../../img/Experiencia Cafetera .png';
import planCordillera from '../../img/plan-cordillera-p.jpg';
import casa from '../../img/Casa-du-velo.png';
import classes from '../../css/styles.module.css';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

const OurExperiences = () => {

    const lang = i18next.language.split('-')[0] || 'en';

    const routes = {
        en: {
            birdWatching: '/experiences/bird-watching',
            horsebackTrails: '/experiences/horseback-trails',
            jungleLights: '/experiences/jungle-lights',
            dayCasaDuvelo: '/experiences/day-casa-duvelo',
            coffeeExperience: '/experiences/coffee-experience',
            TourMountainRange: '/experiences/tour-mountain-range',

        },
        es: {
            birdWatching: '/experiences/bird-watching',
            horsebackTrails: '/experiences/horseback-trails',
            jungleLights: '/experiences/jungle-lights',
            dayCasaDuvelo: '/experiences/day-casa-duvelo',
            coffeeExperience: '/experiences/coffee-experience',
            TourMountainRange: '/experiences/tour-mountain-range',
        },
    };

    const currentRoutes = routes[lang] || routes.en;

    const content = {
        en: {
            title: "Our Experiences",
            plan1_title: "Bird Watching",
            plan1_desc: "Colombian Feathers: Discovering Wings in the Land of Biodiversity",
            plan2_title: "Horseback Trails",
            plan2_desc: "Explore Colombia's Beauty on Horseback",
            plan3_title: "Day at Casa DuVelo",
            plan3_desc: "Explore Elite Cycling in the Heart of the Coffee Axis with Casa DuVelo",
            plan4_title: "Jungle Lights",
            plan4_desc: "Night Safari in Colombian Magic",
            plan5_title: "Coffee Experience",
            plan5_desc: "Adventure Among Palms and Coffee Plants in the Heart of the Axis",
            plan6_title: "Mountain Range Tour",
            plan6_desc: "Tour the Quindiana Mountain Range in a Willys Jeep, enjoying its beautiful landscapes.",
        },
        es: {
            title: "Nuestras Experiencias",
            plan1_title: "Avistamiento de Aves",
            plan1_desc: "Plumas Colombianas: Descubriendo Alas en la Tierra de la Biodiversidad",
            plan2_title: "Rutas a Riendas",
            plan2_desc: "Explora a Caballo la Belleza de Colombia",
            plan3_title: "Día en Casa DuVelo",
            plan3_desc: "Explora el Ciclismo de Élite en el Corazón del Eje Cafetero con Casa DuVelo",
            plan4_title: "Luces de la Selva",
            plan4_desc: "Safari Nocturno en la Magia Colombiana",
            plan5_title: "Experiencia Cafetera",
            plan5_desc: "Aventura entre Palmas y Cafetales en el Corazón del Eje",
            plan6_title: "Tour en la Cordillera",
            plan6_desc: "Recorre la Cordillera Quindiana en un Jeep Willys, disfrutando de sus  hermosos paisajes.",
        }
    };

    return (
        <section>
            <div className={`${classes['nuestras-experiencias']} ${classes['flex-col']}`}>
                <h1 className={classes['project-title']}>{content[lang].title}</h1>
                <div className={`${classes['slide-container']} swiper ${classes.flex} ${classes['card-container']} ${classes['mt-1']} ${classes['mb-2']}`}>
                    <div className={classes['slide-content']}>
                        <div className={`${classes['card-wrapper']} swiper-wrapper`}>
                            <div className={`swiper-button-next ${classes['swiper-navBtn']}`}>
                                <div className={`red-dot ${classes['red-dot']}`}></div>
                            </div>
                            <div className={`swiper-button-prev ${classes['swiper-navBtn2']}`}>
                                <div className={`red-dot ${classes['red-dot']}`}></div>
                            </div>

                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={-13}
                                slidesPerView={3}
                                loop={true}
                                effect={'fade'}
                                grabCursor={true}
                                navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                }}
                                pagination={{
                                    el: ".swiper-pagination",
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    520: {
                                        slidesPerView: 2,
                                    },
                                    950: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={aves} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan1_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan1_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.birdWatching}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={ruta} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan2_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan2_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.horsebackTrails}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={casa} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan3_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan3_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.dayCasaDuvelo}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={luces} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan4_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan4_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.jungleLights}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={Experiencia} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan5_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan5_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.coffeeExperience}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className={classes['card-plan']}>
                                        <img src={planCordillera} alt="img of plan" className={classes.img} />
                                        <h2 className={classes['card-title']}>{content[lang].plan6_title}</h2>
                                        <p className={classes['card-description']}>{content[lang].plan6_desc}</p>
                                        <Link to={`/${lang}${currentRoutes.TourMountainRange}`}>
                                            <button className={classes['main-btn']}>
                                                {i18next.t('View More')}
                                            </button>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurExperiences;
