import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Pages/Home';
import Plans from './Pages/Plans';
import BirdWatching from './Pages/BirdWatching';
import HorsebackTrails from './Pages/HorsebackTrails';
import DayCasaDuvelo from './Pages/DayCasaDuvelo';
import CoffeeExperience from './Pages/CoffeeExperience';
import JungleLights from './Pages/JungleLights';
import TourMountainRange from './Pages/TourMountainRange';
import AboutUs from './Pages/AboutUs';
import Questions from './Pages/FAQ';

const NotFound = () => <Navigate to="/" />;

function Layout() {
 
  return (
    <div>
      <BrowserRouter>
        <Header homeUrl="/" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/:lang/experiences" element={<Plans />} />
          <Route path="/:lang/experiences/bird-watching" element={<BirdWatching />} />
          <Route path="/:lang/experiences/horseback-trails" element={<HorsebackTrails />} />
          <Route path="/:lang/experiences/jungle-lights" element={<JungleLights />} />
          <Route path="/:lang/experiences/day-casa-duvelo" element={<DayCasaDuvelo />} />
          <Route path="/:lang/experiences/coffee-experience" element={<CoffeeExperience />} />
          <Route path="/:lang/experiences/tour-mountain-range" element={<TourMountainRange />} />
          <Route path="/:lang/about-us" element={<AboutUs />} />
          <Route path="/:lang/questions-answers" element={<Questions />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <div style={{display: 'none'}}>
        <a href={'/es/'}> Spanish Version </a> 
        <a href={'/en/'} >English Version</a>
        </div>
    </div>
  );
}

export default Layout;
